import {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  PropsWithChildren
} from 'react'
import classNames from 'classnames'
import Link, { LinkProps } from 'next/link'

import Typography from '@/components/base/Typography'

type HTMLButtonProps = ButtonHTMLAttributes<HTMLButtonElement>
type HTMLAnchorProps = AnchorHTMLAttributes<HTMLAnchorElement>
type NextLinkProps = PropsWithChildren<LinkProps>

type ButtonProps = (HTMLButtonProps | HTMLAnchorProps | NextLinkProps) &
  Partial<{
    link: boolean
    dark: boolean
    color: string
    external: boolean
    className: string
    variant: 'contained' | 'outlined'
  }>

const Button = ({
  link = false,
  dark = false,
  external = false,
  className,
  children,
  variant,
  color,
  ...props
}: ButtonProps) => {
  const variantClasses = classNames(
    'group inline-flex shrink-0 px-sm py-[1.27rem] rounded-[10rem] border-2',
    {
      'text-pink bg-transparent border-transparent':
        variant !== 'contained' && variant !== 'outlined'
    },
    {
      'text-pink bg-white border-white':
        variant === 'contained' && dark && (!color || color === 'pink'),
      'text-cyan bg-white border-white':
        variant === 'contained' && dark && color === 'cyan'
    },
    {
      'text-white bg-pink border-pink': variant === 'contained' && !dark
    },
    {
      'text-white bg-transparent border-white/30': variant === 'outlined'
    }
  )

  const renderButton = () => {
    return (
      <span
        data-label={children as string}
        className={classNames(
          'relative flex overflow-hidden py-[.45rem]',
          'after:absolute after:left-0 after:top-0 after:-my-[.45em] after:w-full after:translate-y-full after:py-[.45rem] after:font-roboto after:text-button after:transition-transform after:duration-300 after:ease-out-cubic after:content-[attr(data-label)] pointer:group-hover:after:translate-y-0'
        )}
      >
        <Typography
          variant="button"
          className="block transition-transform duration-300 ease-out-cubic pointer:group-hover:-translate-y-full"
        >
          {children}
        </Typography>
      </span>
    )
  }

  return link ? (
    external ? (
      <a
        target="_blank"
        className={classNames(variantClasses, className)}
        {...(props as HTMLAnchorProps)}
      >
        {renderButton()}
      </a>
    ) : (
      <Link
        scroll={false}
        className={classNames(variantClasses, className)}
        {...(props as NextLinkProps)}
      >
        {renderButton()}
      </Link>
    )
  ) : (
    <button
      className={classNames(variantClasses, className)}
      {...(props as HTMLButtonProps)}
    >
      {renderButton()}
    </button>
  )
}

export default Button
