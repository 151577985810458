import gsap from 'gsap'

import {
  buttonRevealAnimation,
  paragraphRevealAnimation,
  SharedAnimation,
  titleRevealAnimation
} from './shared'
import { Animation } from '@/types'

export const pushParagraphRevealAnimation = ({
  node,
  reverse,
  mobile,
  context
}: Animation) => {
  if (mobile) return

  const selector = context?.selector || gsap.utils.selector(node)

  const title = selector('[data-motion-title]')[0]
  const paragraph = selector('[data-motion-paragraph]')[0]
  const button = selector('[data-motion-button]')[0]
  const imageParallax = selector('[data-motion-parallax]')[0]
  const imageRatio = selector('[data-motion-ratio]')[0]

  const scrollTrigger = {
    trigger: node,
    once: !reverse,
    start: 'top 92%', // start: 'top bottom',
    markers: false,
    toggleActions: `play none none ${reverse ? 'reverse' : 'none'}`
  }

  const timeline = gsap.timeline({
    scrollTrigger
  })

  if (imageParallax && imageRatio) {
    timeline.add(
      gsap
        .timeline()
        .fromTo(
          imageParallax,
          { scale: 0.5 },
          {
            scale: 1,
            ease: 'power2.out',
            duration: 0.8
          }
        )
        .fromTo(
          imageRatio,
          { scale: 2 },
          {
            scale: 1,
            ease: 'power2.out',
            duration: 0.8
          },
          '<'
        )
    )
  }

  let titleAnimation: SharedAnimation
  if (title) {
    titleAnimation = titleRevealAnimation(title, reverse)
    timeline.add(titleAnimation.timeline, '<')
  }

  let paragraphAnimation: SharedAnimation
  if (paragraph) {
    paragraphAnimation = paragraphRevealAnimation(paragraph, reverse)
    timeline.add(paragraphAnimation.timeline, '<+0.2')
  }

  let buttonAnimation: SharedAnimation
  if (button) {
    buttonAnimation = buttonRevealAnimation(button)
    timeline.add(buttonAnimation.timeline, '<+0.3')
  }

  return () => {
    if (titleAnimation) {
      titleAnimation.revert()
    }

    if (paragraphAnimation) {
      paragraphAnimation.revert()
    }

    if (buttonAnimation) {
      buttonAnimation.revert()
    }
  }
}
