import gsap from 'gsap'

import { batchRevealAnimation } from './shared'
import { Animation } from '@/types'

export const tabsInfoRevealAnimation = ({
  node,
  reverse,
  mobile,
  context
}: Animation) => {
  if (mobile) return

  const selector = context?.selector || gsap.utils.selector(node)

  const items = selector('[data-motion-item]')

  batchRevealAnimation({
    targets: items,
    reverse,
    stagger: {
      amount: 0.2
    }
  })
}
