import gsap from 'gsap'

import {
  paragraphRevealAnimation,
  SharedAnimation,
  titleRevealAnimation
} from './shared'
import { LandingAnimation } from '@/types'

export const homeLandingRevealAnimation = ({
  node,
  webgl,
  reverse,
  mobile,
  context
}: LandingAnimation) => {
  if (mobile) return

  const selector = context?.selector || gsap.utils.selector(node)

  const title = selector('[data-motion-title]')[0]
  const paragraph = selector('[data-motion-paragraph]')[0]

  const image = webgl.image.instance
  const shape = webgl.shape.instance

  const scrollTrigger = {
    trigger: node,
    once: !reverse,
    start: 'top 92%', // start: 'top bottom',
    markers: false,
    toggleActions: `play none none ${reverse ? 'reverse' : 'none'}`
  }

  const timeline = gsap.timeline({
    scrollTrigger
  })

  if (image && shape) {
    const cube = image.getObjectByName('cube')
    const hexagon = image.getObjectByName('hexagon')
    const pyramid = image.getObjectByName('pyramid')

    timeline.add(
      gsap
        .timeline()
        .fromTo(
          image.material.uniforms.uTransition,
          { value: 0 },
          { value: 1, duration: 1.8, ease: 'power3.inOut' }
        )
        .fromTo(
          [hexagon?.scale, pyramid?.scale, cube?.scale],
          { x: 0, y: 0, z: 0 },
          {
            x: 1,
            y: 1,
            z: 1,
            duration: 1.8,
            ease: 'power3.inOut',
            stagger: 0.3
          },
          '<'
        )
        .fromTo(
          shape.material.uniforms.uTransition,
          { value: 0 },
          { value: 1, duration: 2, ease: 'power3.inOut' },
          '<+0.2'
        ),
      '<'
    )
  }

  let titleAnimation: SharedAnimation
  if (title) {
    titleAnimation = titleRevealAnimation(title, reverse)
    timeline.add(titleAnimation.timeline, '<+0.6')
  }

  let paragraphAnimation: SharedAnimation
  if (paragraph) {
    paragraphAnimation = paragraphRevealAnimation(paragraph, reverse)
    timeline.add(paragraphAnimation.timeline, '<+0.4')
  }

  return () => {
    if (titleAnimation) {
      titleAnimation.revert()
    }

    if (paragraphAnimation) {
      paragraphAnimation.revert()
    }
  }
}
