import gsap from 'gsap'

import {
  buttonRevealAnimation,
  paragraphRevealAnimation,
  SharedAnimation,
  titleRevealAnimation
} from './shared'
import { TRANSITION_LEAVE_DURATION } from '@/contexts/transition'
import { Animation } from '@/types'

export const heroRevealAnimation = ({
  node,
  nodeRef,
  reverse,
  mobile,
  context
}: Animation) => {
  if (mobile) return

  const selector =
    context?.selector || gsap.utils.selector(nodeRef?.current || node)

  const overline = selector('[data-motion-overline]')[0]
  const title = selector('[data-motion-title]')[0]
  const paragraph = selector('[data-motion-paragraph]')[0]
  const button = selector('[data-motion-button]')[0]
  const imageParallax = selector('[data-motion-parallax]')[0]
  const imageRatio = selector('[data-motion-ratio]')[0]
  const logos = selector('[data-motion-logo]')

  const scrollTrigger = {
    trigger: node,
    once: !reverse,
    start: 'top 92%', // start: 'top bottom',
    markers: false,
    toggleActions: `play none none ${reverse ? 'reverse' : 'none'}`
  }

  const timeline = gsap.timeline({
    scrollTrigger
  })

  const posterTimeline = gsap.timeline({
    delay: imageRatio ? TRANSITION_LEAVE_DURATION / 3 : 0
  })
  const contentTimeline = gsap.timeline()

  if (imageParallax && imageRatio) {
    posterTimeline.add(
      gsap
        .timeline()
        .fromTo(
          imageParallax,
          { scale: 0 },
          {
            scale: 1,
            ease: 'power2.out',
            duration: 1
          }
        )
        .fromTo(
          imageRatio,
          { scale: 2.5 },
          {
            scale: 1,
            ease: 'power2.out',
            duration: 1
          },
          '<'
        )
    )
  } else if (imageParallax) {
    posterTimeline.add(
      gsap.timeline().fromTo(
        imageParallax,
        { scale: 1.05 },
        {
          scale: 1,
          ease: 'power1.inOut',
          duration: 1.6
        }
      )
    )
  }

  let overlineAnimation: SharedAnimation
  if (overline) {
    overlineAnimation = paragraphRevealAnimation(overline, reverse)
    contentTimeline.add(overlineAnimation.timeline, '<')
  }

  let titleAnimation: SharedAnimation
  if (title) {
    titleAnimation = titleRevealAnimation(title, reverse)
    contentTimeline.add(titleAnimation.timeline, '<+0.1')
  }

  let paragraphAnimation: SharedAnimation
  if (paragraph) {
    paragraphAnimation = paragraphRevealAnimation(paragraph, reverse)
    contentTimeline.add(paragraphAnimation.timeline, '<+0.2')
  }

  let buttonAnimation: SharedAnimation
  if (button) {
    buttonAnimation = buttonRevealAnimation(button)
    contentTimeline.add(buttonAnimation.timeline, '<+0.3')
  }

  let logosAnimation: SharedAnimation
  if (logos && logos.lenght > 0) {
    logosAnimation = buttonRevealAnimation(logos)
    contentTimeline.add(logosAnimation.timeline, '<+0.3')
  }

  timeline.add(posterTimeline).add(contentTimeline, '<+0.3')

  return () => {
    if (overlineAnimation) {
      overlineAnimation.revert()
    }

    if (titleAnimation) {
      titleAnimation.revert()
    }

    if (paragraphAnimation) {
      paragraphAnimation.revert()
    }

    if (buttonAnimation) {
      buttonAnimation.revert()
    }

    if (logosAnimation) {
      logosAnimation.revert()
    }
  }
}
