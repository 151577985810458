import gsap from 'gsap'

import {
  paragraphRevealAnimation,
  SharedAnimation,
  titleRevealAnimation
} from './shared'
import { Animation } from '@/types'

export const contactFormRevealAnimation = ({
  node,
  reverse,
  mobile,
  context
}: Animation) => {
  if (mobile) return

  const selector = context?.selector || gsap.utils.selector(node)

  const overline = selector('[data-motion-overline]')[0]
  const title = selector('[data-motion-title]')[0]
  const paragraph = selector('[data-motion-paragraph]')[0]
  const form = selector('[data-motion-form]')[0]

  const scrollTrigger = {
    trigger: node,
    once: !reverse,
    start: 'top 92%', // start: 'top bottom',
    markers: false,
    toggleActions: `play none none ${reverse ? 'reverse' : 'none'}`
  }

  const timeline = gsap.timeline({
    scrollTrigger
  })

  let overlineAnimation: SharedAnimation
  if (overline) {
    overlineAnimation = paragraphRevealAnimation(overline, reverse)
    timeline.add(overlineAnimation.timeline, '<')
  }

  let titleAnimation: SharedAnimation
  if (title) {
    titleAnimation = titleRevealAnimation(title, reverse)
    timeline.add(titleAnimation.timeline, '<+0.1')
  }

  let paragraphAnimation: SharedAnimation
  if (paragraph) {
    paragraphAnimation = paragraphRevealAnimation(paragraph, reverse)
    timeline.add(paragraphAnimation.timeline, '<+0.2')
  }

  if (form) {
    timeline.add(
      gsap.fromTo(
        form,
        { opacity: 0, y: 40 },
        { opacity: 1, y: 0, duration: 0.8, ease: 'power2.out' }
      ),
      '<+0.2'
    )
  }

  return () => {
    if (overlineAnimation) {
      overlineAnimation.revert()
    }

    if (titleAnimation) {
      titleAnimation.revert()
    }

    if (paragraphAnimation) {
      paragraphAnimation.revert()
    }
  }
}
