/* eslint-disable no-unused-vars */
import { MutableRefObject, RefObject, useRef } from 'react'
import { useGSAP } from '@gsap/react'

import { useLoaderStore } from '@/store/loader'
import { isTouch } from '@/utils/device'
import { useWebGLStore } from '@/webgl/store/loader'

import { useLenis } from '@/contexts/lenis'
import { Animation } from '@/types'

type MotionProps = {
  children: (ref: RefObject<HTMLDivElement>) => React.ReactNode
  animation: (props: Animation<HTMLElement>) => (() => void) | void // gsap.core.Timeline
  nodeRef?: RefObject<HTMLElement>
  revertOnUpdate?: boolean
}

const Motion = ({
  nodeRef,
  children,
  animation,
  revertOnUpdate = true
}: MotionProps) => {
  const root = useRef<HTMLDivElement>(null!)
  const loaded = useLoaderStore((state) => state.pageLoaded)
  const compiled = useWebGLStore((state) => state.compiled)
  const { lenis } = useLenis()

  useGSAP(
    (context) => {
      if (!lenis || !loaded || !compiled) return

      const revert = animation({
        node: root.current,
        mobile: isTouch,
        reverse: false,
        nodeRef,
        context
      })

      return () => {
        if (revert) {
          revert()
        }
      }
    },
    {
      dependencies: [lenis, loaded, compiled],
      scope: nodeRef || root,
      revertOnUpdate
    }
  )

  return children(root)
}

export default Motion
