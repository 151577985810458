import gsap from 'gsap'

import { batchRevealAnimation } from './shared'
import { Animation } from '@/types'

export const doubleBannerRevealAnimation = ({
  node,
  reverse,
  mobile,
  context
}: Animation) => {
  if (mobile) return

  const selector = context?.selector || gsap.utils.selector(node)

  const banners = selector('[data-motion-banner]')

  batchRevealAnimation({
    targets: banners,
    reverse,
    stagger: {
      amount: 0.12
    }
  })
}
