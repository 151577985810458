import gsap from 'gsap'

import { batchRevealAnimation } from './shared'
import { Animation } from '@/types'

export const timelineRevealAnimation = ({
  node,
  reverse,
  mobile,
  context
}: Animation) => {
  if (mobile) return

  const selector = context?.selector || gsap.utils.selector(node)

  const line = selector('[data-motion-line]')[0]
  const cards = selector('[data-motion-step-card]')
  const dots = selector('[data-motion-step-dot]')

  const scrollTrigger = {
    trigger: node,
    once: !reverse,
    scrub: true,
    start: 'top bottom',
    end: 'bottom bottom',
    markers: false,
    toggleActions: `play none none ${reverse ? 'reverse' : 'none'}`
  }

  const timeline = gsap.timeline({
    scrollTrigger
  })

  timeline.fromTo(
    line,
    { scaleY: 0.05 },
    {
      scaleY: 1,
      duration: 1,
      transformOrigin: 'center top',
      ease: 'none'
    }
  )

  batchRevealAnimation({
    targets: cards,
    reverse,
    stagger: {
      amount: 0.4
    }
  })

  batchRevealAnimation({
    targets: dots,
    reverse,
    stagger: {
      amount: 0.4
    }
  })
}
