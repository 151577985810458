import { HTMLAttributes, ReactNode, RefObject, useRef } from 'react'
import { useGSAP } from '@gsap/react'
import classNames from 'classnames'
import gsap from 'gsap'

import { useLenis } from '@/contexts/lenis'

type ImageParallaxProps = Omit<HTMLAttributes<HTMLDivElement>, 'children'> & {
  fill?: boolean
  // eslint-disable-next-line no-unused-vars
  children: (ref: RefObject<HTMLImageElement>) => ReactNode
}

const ImageParallax = ({
  children,
  className,
  fill = false,
  ...props
}: ImageParallaxProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const image = useRef<HTMLImageElement>(null)
  const { lenis } = useLenis()

  useGSAP(
    () => {
      if (!lenis || !image.current) return

      gsap.to(image.current, {
        scrollTrigger: {
          trigger: image.current,
          start: 'top 100%',
          end: 'bottom 0%',
          markers: false,
          scrub: true,
          once: false
        },
        yPercent: -13,
        ease: 'none'
      })
    },
    { scope: image, dependencies: [lenis] }
  )

  return (
    <span
      ref={ref}
      className={classNames(
        'translate isolate overflow-hidden [&_img]:!h-[115%]',
        {
          'relative block': !fill,
          'absolute left-0 top-0 h-full w-full': fill
        },
        className
      )}
      {...props}
    >
      {children(image)}
    </span>
  )
}

export default ImageParallax
