import gsap from 'gsap'

import { batchRevealAnimation } from './shared'
import { Animation } from '@/types'

export const sliderRevealAnimation = ({
  node,
  reverse,
  mobile,
  context
}: Animation) => {
  if (mobile) return

  const selector = context?.selector || gsap.utils.selector(node)

  const slides = selector('[data-motion-slide]')

  batchRevealAnimation({
    targets: slides,
    reverse,
    stagger: {
      amount: 0.3
    }
  })
}
