import gsap from 'gsap'
import { forEach } from 'lodash'

import { SharedAnimation, titleRevealAnimation } from './shared'
import { Animation } from '@/types'

export const numbersRevealAnimation = ({
  node,
  reverse,
  mobile,
  context
}: Animation) => {
  if (mobile) return

  const selector = context?.selector || gsap.utils.selector(node)

  const title = selector('[data-motion-title]')[0]
  const values = selector('[data-motion-value]')
  const lines = selector('[data-motion-line]')
  const descriptions = selector('[data-motion-description]')

  const scrollTrigger = {
    trigger: node,
    once: !reverse,
    start: 'top 92%', // start: 'top bottom',
    markers: false,
    toggleActions: `play none none ${reverse ? 'reverse' : 'none'}`
  }

  const timeline = gsap.timeline({
    scrollTrigger
  })

  let titleAnimation: SharedAnimation
  if (title) {
    titleAnimation = titleRevealAnimation(title, reverse)
    timeline.add(titleAnimation.timeline, '<')
  }

  if (values) {
    const valuesTimeline = gsap.timeline()

    forEach(values, (element) => {
      const value = parseFloat(element.getAttribute('data-motion-value'))
      const needsDecimals = value.toString().split('.').length > 1
      const valueTweener = { value: 1 }

      valuesTimeline.to(
        valueTweener,
        {
          value,
          duration: 2,
          ease: 'power2.out',
          onUpdate: () => {
            element.innerHTML = valueTweener.value.toFixed(
              needsDecimals ? 1 : 0
            )
          }
        },
        '<'
      )
    })

    const rootTimeline = gsap
      .timeline({ defaults: {} })
      .add(valuesTimeline)
      .fromTo(
        lines,
        { scaleX: 0 },
        {
          scaleX: 1,
          transformOrigin: 'left center',
          duration: 0.8,
          ease: 'power3.inOut',
          stagger: 0.1
        },
        '<+.1'
      )

    if (descriptions.length > 0) {
      rootTimeline.fromTo(
        descriptions || {},
        { yPercent: 100, opacity: 0 },
        {
          opacity: 1,
          yPercent: 0,
          ease: 'power3.out',
          duration: 0.6,
          stagger: 0.1
        },
        '<+.1'
      )
    }

    timeline.add(rootTimeline, '<')
  }

  return () => {
    if (titleAnimation) {
      titleAnimation.revert()
    }
  }
}
