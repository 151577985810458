import gsap from 'gsap'

import { batchRevealAnimation } from './shared'
import { Animation } from '@/types'

export const accordionsRevealAnimation = ({
  node,
  reverse,
  mobile,
  context
}: Animation) => {
  if (mobile) return

  const selector = context?.selector || gsap.utils.selector(node)

  const accordions = selector('[data-motion-accordion]')

  batchRevealAnimation({
    targets: accordions,
    reverse,
    stagger: {
      amount: 0.4
    }
  })
}
