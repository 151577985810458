import gsap from 'gsap'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import SplitText from 'gsap/dist/SplitText'
import { forEach } from 'lodash'

export type SharedAnimation = {
  timeline: gsap.core.Timeline
  revert: () => void
}

export const titleRevealAnimation = (
  target: gsap.DOMTarget,
  reverse: boolean
): SharedAnimation => {
  const splitText = new SplitText(target, {
    type: 'lines',
    linesClass: 'line'
  })
  new SplitText(target, { type: 'lines', linesClass: 'line-wrap' })

  return {
    timeline: gsap
      .timeline({
        onComplete: () => {
          if (reverse) return
          splitText.revert()
        }
      })
      .fromTo(
        target,
        { scale: 1.1 },
        {
          scale: 1,
          ease: 'power2.out',
          duration: 1.2
        }
      )
      .fromTo(
        splitText.lines,
        { yPercent: 100 },
        {
          yPercent: 0,
          ease: 'power2.out',
          duration: 0.6,
          stagger: { amount: 0.2 }
        },
        '<'
      ),

    revert: () => {
      splitText.revert()
    }
  }
}

export const paragraphRevealAnimation = (
  target: gsap.DOMTarget,
  reverse: boolean
): SharedAnimation => {
  const splitText = new SplitText(target, {
    type: 'lines',
    linesClass: 'line'
  })
  new SplitText(target, { type: 'lines', linesClass: 'line-wrap' })

  forEach(splitText.lines, (line) => {
    if (line.innerHTML.length === 1) {
      line.innerHTML = '<br>'
    }
  })

  return {
    timeline: gsap
      .timeline({
        onComplete: () => {
          if (reverse) return
          splitText.revert()
        }
      })
      .fromTo(
        splitText.lines,
        { yPercent: 120, opacity: 0 },
        {
          opacity: 1,
          yPercent: 0,
          ease: 'power3.out',
          duration: 0.6,
          stagger: { amount: 0.2 }
        }
      ),

    revert: () => {
      splitText.revert()
    }
  }
}

export const buttonRevealAnimation = (
  target: gsap.DOMTarget
): SharedAnimation => {
  return {
    timeline: gsap
      .timeline()
      .fromTo(
        target,
        { opacity: 0, y: 40 },
        { opacity: 1, y: 0, duration: 0.8, ease: 'power2.out' }
      ),

    revert: () => {
      return
    }
  }
}

export const batchRevealAnimation = ({
  targets,
  reverse,
  stagger
}: {
  targets: gsap.DOMTarget
  reverse: boolean
  stagger?: gsap.NumberValue | gsap.StaggerVars
}) => {
  ScrollTrigger.batch(targets, {
    // interval: 0.1, // time window (in seconds) for batching to occur.
    // batchMax: 3,   // maximum batch size (targets)
    once: !reverse,
    onEnter: (batch) =>
      gsap.fromTo(
        batch,
        { opacity: 0, y: 30 },
        {
          opacity: 1,
          y: 0,
          duration: 0.6,
          ease: 'power2.out',
          stagger
        }
      )
  })
}
