import { HTMLAttributes } from 'react'
import classNames from 'classnames'

export type SpacerProps = HTMLAttributes<HTMLHRElement> & {
  variant?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'
}

const Spacer = ({ variant = '2xl', className, ...props }: SpacerProps) => {
  const variantClasses = {
    xxs: 'pt-xxs md:pt-xs',
    xs: 'pt-xs md:pt-md',
    sm: 'pt-sm md:pt-xl',
    md: 'pt-md md:pt-3xl',
    lg: 'pt-lg md:pt-4xl',
    xl: 'pt-xl md:pt-5xl',
    '2xl': 'pt-2xl md:pt-6xl'
  }

  return (
    <hr
      className={classNames(
        'border-0 bg-transparent',
        variantClasses[variant],
        className
      )}
      {...props}
    />
  )
}

export default Spacer
