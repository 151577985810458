import gsap from 'gsap'

import { batchRevealAnimation } from './shared'
import { Animation } from '@/types'

export const imageCardGridRevealAnimation = ({
  node,
  reverse,
  mobile,
  context
}: Animation) => {
  if (mobile) return

  const selector = context?.selector || gsap.utils.selector(node)

  const pillars = selector('[data-motion-image-card]')

  batchRevealAnimation({
    targets: pillars,
    reverse,
    stagger: {
      amount: 0.2
    }
  })
}
