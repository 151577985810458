import gsap from 'gsap'

import { paragraphRevealAnimation, SharedAnimation } from './shared'
import { Animation } from '@/types'

export const indexParagraphRevealAnimation = ({
  node,
  reverse,
  mobile,
  context
}: Animation) => {
  if (mobile) return

  const selector = context?.selector || gsap.utils.selector(node)

  const subtitle = selector('[data-motion-subtitle]')[0]
  const paragraph = selector('[data-motion-paragraph]')[0]

  const scrollTrigger = {
    trigger: node,
    once: !reverse,
    start: 'top 92%', // start: 'top bottom',
    markers: false,
    toggleActions: `play none none ${reverse ? 'reverse' : 'none'}`
  }

  const timeline = gsap.timeline({
    scrollTrigger
  })

  let subtitleAnimation: SharedAnimation
  if (subtitle) {
    subtitleAnimation = paragraphRevealAnimation(subtitle, reverse)
    timeline.add(subtitleAnimation.timeline, '<')
  }

  let paragraphAnimation: SharedAnimation
  if (paragraph) {
    paragraphAnimation = paragraphRevealAnimation(paragraph, reverse)
    timeline.add(paragraphAnimation.timeline, '<+0.2')
  }

  return () => {
    if (subtitleAnimation) {
      subtitleAnimation.revert()
    }

    if (paragraphAnimation) {
      paragraphAnimation.revert()
    }
  }
}
