import { boolean, mixed, number, object, ref, string } from 'yup'

import { WPCF7FormField } from '@/types'

export const createYupSchema = (config: WPCF7FormField[], lang = 'en') => {
  const errors = require(`@/utils/form/errors/${lang}.json`) // eslint-disable-line
  const schema = config
    .filter(
      (c) =>
        c.type !== 'space' && c.type !== 'title' && c.type !== 'multiple_dates'
    )
    .reduce((obj, item) => {
      let value: any = mixed()
      if (
        item.type === 'text' ||
        item.type === 'date' ||
        item.type === 'textarea' ||
        item.type === 'select' ||
        item.type === 'radio' ||
        item.type === 'address' ||
        item.type === 'website' ||
        item.type === 'hidden'
      ) {
        value = string()
        if (item.isRequired) value = value.required(errors['required'])
      }
      if (item.type === 'email') {
        value = string().email(errors['email'])
        if (item.isRequired) value = value.required(errors['required'])
      }
      if (item.type === 'password') {
        value = string()
        if (item.isRequired)
          value = value.min(8, errors['password']).required(errors['required'])
      }
      if (item.type === 'confirm_password') {
        value = string()
        if (item.isRequired)
          value = value
            .required(errors['required'])
            .oneOf([ref('user_pass'), null], errors['confirm_password'])
      }
      if (item.type === 'tel' || item.type === 'phone') {
        value = number().typeError(errors['tel'])
        if (item.isRequired) value = value.required(errors['required'])
      }
      if (item.type === 'checkbox' || item.type === 'consent') {
        value = boolean()
        if (item.isRequired)
          value = value
            .oneOf([true], errors['checkbox'])
            .required(errors['required'])
      }
      if (item.type === 'file' || item.type === 'fileupload') {
        value = mixed()
        if (item.isRequired) value = value.required(errors['required'])
      }

      return {
        ...obj,
        [`input_${item.id}`]: value
      }
    }, {})

  return object().shape(schema)
}

export const createInitialValues = (
  fields: WPCF7FormField[],
  initialValues = []
) => {
  const values = fields.reduce((obj, item) => {
    return {
      ...obj,
      [`input_${item.id}`]: item.type === 'consent' ? false : ''
    }
  }, {})
  return Object.assign(values, ...initialValues)
}

export const convertJsontoFormData = (obj: any) => {
  const formData = new FormData()

  for (const key in obj) {
    // eslint-disable-line no-restricted-syntax
    if (obj.hasOwnProperty(key)) {
      // eslint-disable-line no-prototype-builtins
      formData.append(key, obj[key])
    }
  }
  return formData
}
