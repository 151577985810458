import { ElementType, InputHTMLAttributes, useState } from 'react'
import classNames from 'classnames'

import SVGIcon from '@/components/base/SVGIcon'
import Typography from '@/components/base/Typography'

import { WPCF7FormField } from '@/types'

type FormControlProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'onChange' | 'onBlur'
> &
  Partial<
    WPCF7FormField & {
      error?: boolean
      rounded?: boolean
      options?: { value: string; text: string }[]
      // eslint-disable-next-line no-unused-vars
      onChange?: (e: any) => void
      // eslint-disable-next-line no-unused-vars
      onBlur?: (e: any) => void
    }
  >

type SelectControlProps = Omit<FormControlProps, 'type'>

const SelectControl = ({
  id,
  name,
  value,
  placeholder,
  options = [],
  required,
  disabled,
  error,
  onChange,
  onBlur
}: SelectControlProps) => {
  const [focus, setFocus] = useState(false)

  return (
    <div className="relative">
      <button
        tabIndex={-1}
        className={classNames(
          'text-regular pointer-events-none flex h-[4.4rem] w-full items-center justify-between rounded-xxs border bg-white px-xs font-roboto outline-none',
          {
            'border-pink ring-2 ring-pink/20': focus && !error,
            'border-error ring-2 ring-error/50': focus && error
          },
          {
            'border-neutral-90': !error,
            'border-error focus:ring-error/50': error
          }
        )}
      >
        <Typography
          variant="body"
          className={classNames('!m-0', {
            'text-neutral-50': value === '',
            'text-neutral-20': value !== ''
          })}
        >
          {value || placeholder}
        </Typography>
        <SVGIcon
          symbol="select"
          className="h-[.8rem] w-[1.2rem] fill-neutral-20"
        />
      </button>
      <select
        id={id}
        name={name}
        value={value}
        required={required}
        disabled={disabled}
        className="absolute left-0 top-0 flex h-full w-full px-xs opacity-0"
        onFocus={() => setFocus(true)}
        onChange={onChange}
        onBlur={(event) => {
          setFocus(false)
          onBlur && onBlur(event)
        }}
      >
        <option value="-1" disabled>
          {placeholder}
        </option>
        {options?.map((option: { value: string; text: string }, index) => (
          <option key={index} value={option.value}>
            {option.text}
          </option>
        ))}
      </select>
    </div>
  )
}

const FormControl = ({
  id,
  type,
  label,
  checked,
  placeholder,
  className,
  rounded = false,
  children,
  error,
  ...props
}: FormControlProps) => {
  const Component = (type === 'textarea' ? 'textarea' : 'input') as ElementType

  return (
    <div
      className={classNames(className, {
        hidden: type === 'hidden'
      })}
    >
      {type === 'consent' ? (
        <label htmlFor={id} className="relative flex items-start space-x-xs">
          <input
            id={id}
            type="checkbox"
            checked={checked}
            className="h-[1.2rem] w-[1.2rem] opacity-0 [&:checked+div]:after:bg-pink"
            {...props}
          />
          <div
            className={classNames(
              'absolute -top-[.3rem] left-0 !m-0 h-[1.2rem] w-[1.2rem] shrink-0 cursor-pointer rounded-full border border-neutral-60 bg-white',
              'after:absolute after:left-1/2 after:top-1/2 after:h-xxs after:w-xxs after:-translate-x-1/2 after:-translate-y-1/2 after:rounded-full',
              {
                'border-error': error
              }
            )}
          />
          <Typography
            variant="small"
            className={classNames(
              'text-neutral-30',
              '[&>#required-data]:mt-xxs [&>#required-data]:inline-block',
              {
                '!text-error': error
              }
            )}
          >
            {label}
          </Typography>
        </label>
      ) : type === 'select' ? (
        <SelectControl
          id={id}
          placeholder={placeholder}
          error={error}
          {...props}
        />
      ) : (
        <Component
          id={id}
          type={type === 'fileupload' ? 'file' : type}
          {...(type === 'fileupload' && { accept: 'image/*,.pdf,.doc' })}
          placeholder={placeholder}
          className={classNames(
            'flex w-full appearance-none border bg-transparent font-roboto text-body focus:ring-2',
            'bg-white text-neutral-20 outline-none placeholder:text-neutral-50',
            { 'min-h-[15rem] py-[1.2rem]': type === 'textarea' },
            {
              'h-[4.4rem] rounded-xxs px-xs': !rounded,
              'h-[6rem] rounded-full px-lg shadow-3xl': rounded
            },
            {
              'border-transparent': rounded && !error,
              'border-neutral-90': !rounded && !error,
              'focus:border-pink focus:ring-pink/20': !error,
              'border-error focus:ring-error/50': error
            },
            {
              'pl-0 text-neutral-50 file:mr-8 file:h-full file:cursor-pointer file:border-0 file:bg-pink file:px-4 file:py-2 file:text-body file:text-white file:transition-opacity file:duration-300 file:hover:opacity-80':
                type === 'fileupload'
            }
          )}
          {...props}
        />
      )}
      {children}
    </div>
  )
}

export default FormControl
